import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/Devfest2023/Devfest2023/src/layout/mdx.tsx";
export const metadata = {
  title: "Code de Conduite"
};
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const PrimarySection = makeShortcode("PrimarySection");
const layoutProps = {
  metadata,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PrimarySection mdxType="PrimarySection">
      <p>{`Le GDG Nantes (organisateurs du DevFest Nantes) est fier d'être une communauté ouverte, respectueuse des différences et de la diversité. Nous voulons que tous les participants et participantes (participants, conférenciers,…) vivent une expérience agréable et enrichissante. Cela implique que tous les participants et participantes, orateurs et oratrices, sponsors et bénévoles respectent ce code de conduite.`}</p>
      <h2>{`Version courte, lisible et explicite du “Code of Conduct policy”`}</h2>
      <p>{`DevFest Nantes s’engage à fournir une expérience de conférence sans harcèlement à tout le monde, indépendamment de leur :`}</p>
      <ul>
        <li parentName="ul">{`sexe`}</li>
        <li parentName="ul">{`identité et expression de genre`}</li>
        <li parentName="ul">{`orientation sexuelle`}</li>
        <li parentName="ul">{`invalidité`}</li>
        <li parentName="ul">{`apparence physique`}</li>
        <li parentName="ul">{`taille`}</li>
        <li parentName="ul">{`origine`}</li>
        <li parentName="ul">{`âge`}</li>
        <li parentName="ul">{`religion`}</li>
        <li parentName="ul">{`choix technologiques`}</li>
      </ul>
      <p>{`Nous ne tolérons aucune forme de harcèlement des participants et participantes à la conférence. Le langage et les images sexuels ne sont appropriés pour aucun lieu de la conférence, y compris les talks, les codelabs, les animations, les soirées, les réseaux sociaux. Les participants et participantes de la conférence qui enfreignent ces règles peuvent être sanctionnés ou expulsés de la conférence sans remboursements, à la discrétion des organisateurs de la conférence.`}</p>
      <h2>{`Version plus longue`}</h2>
      <p>{`DevFest Nantes s’engage à fournir une expérience de conférence sans harcèlement à tout le monde, indépendamment de leur :`}</p>
      <ul>
        <li parentName="ul">{`sexe`}</li>
        <li parentName="ul">{`identité et expression de genre`}</li>
        <li parentName="ul">{`orientation sexuelle`}</li>
        <li parentName="ul">{`invalidité`}</li>
        <li parentName="ul">{`apparence physique`}</li>
        <li parentName="ul">{`taille`}</li>
        <li parentName="ul">{`origine`}</li>
        <li parentName="ul">{`âge`}</li>
        <li parentName="ul">{`religion`}</li>
        <li parentName="ul">{`choix technologiques`}</li>
      </ul>
      <p>{`Nous ne tolérons aucune forme de harcèlement des participants et participantes à la conférence. Le langage et les images sexuels ne sont appropriés pour aucun lieu de conférence, y compris les talks, les codelabs, les animations, les soirées, les réseaux sociaux. Les participants et participantes à la conférence qui enfreignent ces règles peuvent être sanctionnés ou expulsés de la conférence sans remboursements, à la discrétion des organisateurs de la conférence.`}</p>
      <p>{`Le harcèlement comprend, sans toutefois s’y limiter:`}</p>
      <ul>
        <li parentName="ul">{`Commentaires verbaux qui renforcent les structures sociales de domination, liés au genre, à l’identité et à l’expression de genre, à l’orientation sexuelle, au handicap, à l’apparence physique, à la taille, à l’origine, à l'âge, à la religion, aux choix technologiques.`}</li>
        <li parentName="ul">{`Images sexuelles dans les espaces publics`}</li>
        <li parentName="ul">{`Intimidation délibérée, harcèlement criminel ou suivi`}</li>
        <li parentName="ul">{`Photographie ou enregistrement forcé`}</li>
        <li parentName="ul">{`Perturbation prolongée des discussions ou d’autres événements`}</li>
        <li parentName="ul">{`Contact physique inapproprié`}</li>
        <li parentName="ul">{`Attention sexuelle inopportun`}</li>
        <li parentName="ul">{`Nudité dans les espaces publics`}</li>
        <li parentName="ul">{`Défendre ou encourager l’un des comportements ci-dessus`}</li>
        <li parentName="ul">{`Les participants et participantes invités à mettre fin à tout comportement de harcèlement sont tenus de s’y conformer immédiatement.`}</li>
      </ul>
      <p>{`Les sponsors présents dans le hall d’exposition ou lors des activités similaires sont également soumis à la politique anti-harcèlement. Les sponsors ne doivent pas utiliser d’images, d’activités ou autre matériel sexualisés. Le personnel du stand (y compris les bénévoles) ne doit pas utiliser de vêtements / uniformes / costumes sexualisés, ni créer un environnement sexualisé. Un stand pourra être ainsi fermé en cas de manquement à ces règles.`}</p>
      <p>{`Si un participant ou une participante adopte un comportement harcelant, les organisateurs de l'événement se réservent le droit de prendre toutes les mesures nécessaires pour que l'événement reste un environnement accueillant pour tous les participants et participantes. Cela inclut l’avertissement du délinquant ou l’expulsion de la conférence sans remboursement.`}</p>
      <p>{`Les organisateurs d’événements peuvent prendre des mesures pour remédier à tout ce qui est conçu pour perturber l’événement ou avoir pour impact manifeste de rendre l’environnement hostile pour les participants et participantes.`}</p>
      <p>{`Nous nous attendons à ce que les participants et participantes suivent ces règles sur tous les lieux de l’événement et à toutes les activités sociales liées à l’événement (slack, twitter, mailing, …). Nous pensons que les gens devraient également suivre ces règles en dehors des activités de l'événement !`}</p>
      <h2>{`Besoin de signaler`}</h2>
      <p>{`Si quelqu’un vous dérange de quelque manière que ce soit, nous vous demandons de contacter immédiatement les organisateurs qui mettront en place les mesures nécessaires. Nous voulons que le DevFest soit respectueux des autres et en fasse un événement socialement agréable.`}</p>
      <p>{`Un personnel formé de la conférence peut être identifié par des t-shirts "DevFest Safe" ou "Production".`}</p>
      <img src="/images/tshirt-devfestsafe.png" height="300px" />
      <img src="/images/tshirt-devfestorga.png" height="300px" />
      <p>{`Un stand "DevFest Safe" sera aussi mis en place sur l'espace lounge pour vous renseigner et vous accueillir en cas de besoins. Ce stand sera affiché sur le plan disponible sur votre badge. Le harcèlement et les autres infractions au code de conduite réduisent la valeur de notre événement pour tous. Nous voulons que vous soyez heureux lors de notre événement. Des gens comme vous font de notre événement un meilleur endroit.`}</p>
      <p>{`Vous pouvez faire un rapport personnellement.`}</p>
      <p><strong parentName="p">{`Rapport personnel`}</strong></p>
      <p>{`Pour réaliser un rapport personnel veuillez :`}</p>
      <p>{`Contacter un membre du personnel identifié par le t-shirt "DevFest Safe" ou "Production".`}</p>
      <p>{`Lorsque vous réalisez un rapport personnel, les organisateurs s’assureront que vous êtes en sécurité et que vous ne pouvez pas être entendu. Ils peuvent impliquer d’autres membres du personnel de l’événement pour s’assurer que votre rapport est géré correctement. Une fois en sécurité, nous vous demanderons de nous raconter ce qui s’est passé. Cela peut être bouleversant, mais nous le ferons le plus respectueusement possible et vous pourrez faire appel à quelqu’un pour vous soutenir. On ne vous demandera pas d’affronter qui que ce soit et nous ne dirons à personne qui vous êtes.`}</p>
      <p>{`Notre équipe se fera un plaisir de vous aider à contacter la sécurité des hôtels / sites, les forces de l’ordre locales, les services d’assistance locaux, à vous escorter ou de vous aider à vous sentir en sécurité pendant toute la durée de l'événement. Nous apprécions votre présence.`}</p>
      <p><strong parentName="p">{`Données du rapport`}</strong></p>
      <p>{`Si vous faites un rapport par courrier électronique, veuillez inclure :`}</p>
      <ul>
        <li parentName="ul">{`Le titre avec le format suivant : `}{`[DevFest Nantes]`}{` COC Violation`}</li>
        <li parentName="ul">{`Vos coordonnées (afin que nous puissions vous contacter si nous devons faire un suivi)`}</li>
        <li parentName="ul">{`Date et heure de l’incident`}</li>
        <li parentName="ul">{`Lieu de l’incident`}</li>
        <li parentName="ul">{`Si l’incident est en cours`}</li>
        <li parentName="ul">{`Description de l’incident`}</li>
        <li parentName="ul">{`Informations d’identification de la personne déclarée : nom, apparence physique, taille, vêtements, accent vocal, informations d’identification du badge telles que nom de l’entreprise ou numéro de badge`}</li>
        <li parentName="ul">{`Autres circonstances entourant l’incident`}</li>
        <li parentName="ul">{`Autres personnes impliquées ou témoins de l’incident et leurs coordonnées ou description`}</li>
        <li parentName="ul">{`Confidentialité`}</li>
      </ul>
      <p>{`Tous les rapports resteront confidentiels. Lorsque nous discutons des incidents avec des personnes signalées, nous anonymisons autant que possible les détails afin de protéger la vie privée des rapporteurs.`}</p>
      <p>{`Cependant, certains incidents se produisent lors d’interactions individuelles et, même si les détails sont anonymisés, la personne rapportée peut être en mesure de deviner qui a fait le rapport. Si vous avez des préoccupations concernant les représailles ou votre sécurité personnelle, veuillez les noter dans votre rapport. Nous vous encourageons toujours à faire votre rapport afin de pouvoir vous aider tout en protégeant la sécurité des participants. Dans certains cas, nous pouvons compiler plusieurs rapports anonymisés en un modèle de comportement et agir en conséquence.`}</p>
      <p>{`Dans certains cas, nous pouvons déterminer qu’une déclaration publique devra être faite. Si tel est le cas, les identités de toutes les victimes et de tous les rapporteurs resteront confidentielles, à moins que ces personnes ne nous informent du contraire.`}</p>
      <h2>{`Contacts`}</h2>
      <p>{`En cas de conflits d’intérêts, vous pouvez contacter individuellement :`}</p>
      <ul>
        <li parentName="ul">{`Equipe DevFest Safe : +33 6 11 86 72 35`}</li>
        <li parentName="ul">{`Audrey Le Mercier`}
          <ul parentName="li">
            <li parentName="ul">{`Responsable de la diversité et de la relation avec les intervenants`}</li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "mailto:audrey@gdgnantes.com"
              }}>{`audrey@gdgnantes.com`}</a></li>
          </ul>
        </li>
        <li parentName="ul">{`Jean-François Garreau`}
          <ul parentName="li">
            <li parentName="ul">{`Co-fondateur du DevFest Nantes`}</li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "mailto:jef@gdgnantes.com"
              }}>{`jef@gdgnantes.com`}</a></li>
          </ul>
        </li>
        <li parentName="ul">{`Le compte du DevFestNantes en mp sur Twitter `}</li>
      </ul>
      <h2>{`Licence`}</h2>
      <p>{`Ce code de conduite est issu de l’exemple de politique du wiki Geek Feminism , créé par l’Initiative Ada et d’autres volontaires , sous licence Creative Commons Zero.`}</p>
    </PrimarySection>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      